import React from "react";
import Seo from "../components/Seo";
import Layout from "../layouts/index";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <Seo
          title="404"
          description="You just hit a page that doesn&#39;t exist..."
        />
        <h1>Not Found</h1>
        <p>You just hit a page that doesn&#39;t exist...</p>
      </Layout>
    );
  }
}

export default NotFoundPage;
